export const productShadesInitFormData = (part) => {
  switch (part) {
    default:
    case 'haircolor':
      return [{ rgbColor: [0, 0, 0], strength: 0 }];
    case 'lipstick':
      return [
        {
          rgbColor: [0, 0, 0],
          strength: 0,
          gloss: 0,
          saturability: 0,
          shining: 0,
        },
      ];
    case 'lipliner':
      return [
        {
          rgbColor: [0, 0, 0],
          strength: 0,
          gloss: 0,
          saturability: 0,
          shining: 0,
        },
      ];
    case 'eyeshadow':
      return [
        {
          rgbColor: [0, 0, 0],
          strength: 0,
          finish: '',
        },
      ];
    case 'eyeliner':
      return [
        {
          rgbColor: [0, 0, 0],
          strength: 0,
        },
      ];
    case 'eyebrow':
      return [
        {
          rgbColor: [0, 0, 0],
          strength: 0,
        },
      ];
    case 'mascara':
      return [
        {
          rgbColor: [0, 0, 0],
          strength: 0,
        },
      ];
    case 'stampliner':
      return [
        {
          rgbColor: [0, 0, 0],
          strength: 0,
        },
      ];
    case 'eyecolor':
      return [
        {
          rgbColor: [0, 0, 0],
          strength: 0,
        },
      ];
    case 'blush':
      return [
        {
          rgbColor: [0, 0, 0],
          strength: 0,
        },
      ];
    case 'foundation':
      return [
        {
          rgbColor: [0, 0, 0],
          strength: 0,
        },
      ];
    case 'conrour':
      return [
        {
          rgbColor: [0, 0, 0],
          strength: 0,
        },
      ];
    case 'nail':
      return [
        {
          rgbColor: [0, 0, 0],
          strength: 0,
        },
      ];
  }
};
