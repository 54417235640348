import { Add, Delete, Edit, MoreHoriz, Refresh, Search } from '@material-ui/icons';
import LocalDate from 'components/Form/LocalDate';
import Select from 'components/Form/Select';
import Single from 'components/Form/Single';
import IndexPage from 'pages/IndexPage';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as action from 'redux/actions';
import * as yup from 'yup';

const HaircolorIndexPage = ({ data, dispatch, rowsPerPage, formData, page, total, audiences, styles }) => {
  const [searchValue, setSearchValue] = useState('');
  useEffect(() => {
    dispatch(action.fetchAudiences({ pageSize: 1000 }));
    dispatch(action.fetchStyles({ pageSize: 1000, part: 'haircolor' }));
    dispatch(action.fetchProducts({ page, pageSize: rowsPerPage, part: 'haircolor' }));
    dispatch(action.setFormData({ audiences: [] }));
    return () => {};
  }, [dispatch]);

  const config = {
    category: 'masterdata',
    categoryHierarchy: ['materdata', 'products', 'haircolor'],
    columns: [
      {
        name: 'action',
        actions: [
          {
            name: 'edit',
            icon: Edit,
            onClick: ({ id }) => {
              dispatch(action.openEditProductModal(id));
            },
            title: 'Edit',
          },
          {
            name: 'delete',
            icon: Delete,
            onClick: (data) => {
              dispatch(action.openDeleteProductModal(data));
            },
            title: 'Delete',
          },
          {
            name: 'details',
            icon: MoreHoriz,
            path: '/haircolor',
            title: 'Details',
          },
        ],
        text: 'Actions',
      },
      {
        name: 'name',
        text: 'Name',
      },
      {
        name: 'range',
        text: 'Range',
      },
      {
        name: 'createdDate',
        component: LocalDate,
        text: 'Created Date',
      },
    ],
    modals: [
      {
        name: 'createProduct',
        fields: [
          {
            name: 'part',
            component: Single,
            label: 'Part',
            required: true,
            readonly: true,
            validation: yup.string().required('Part is required'),
          },
          {
            name: 'name',
            autoFocus: true,
            component: Single,
            label: 'Name',
            required: true,
            validation: yup.string().required('Name is required'),
          },
          {
            name: 'range',
            component: Single,
            label: 'Range',
            required: true,
            validation: yup.string().required('Range is required'),
          },
          // {
          //   name: 'shadeUrl',
          //   component: Single,
          //   label: 'Shade URL',
          // },
          // {
          //   name: 'shadeName',
          //   component: Select,
          //   label: 'Natural Shade Name',
          //   options: [
          //     { value: '', text: '--' },
          //     { value: 'BlackBlack', text: 'Black Black' },
          //     { value: 'BlackBrown', text: 'Black Brown' },
          //     { value: 'BlondDark', text: 'Blond Dark' },
          //     { value: 'BlondLight', text: 'Blond Light' },
          //     { value: 'BrownChocolate', text: 'Brown Chocolate' },
          //     { value: 'BrownDark', text: 'Brown Dark' },
          //     { value: 'BrownLight', text: 'Brown Light' },
          //     { value: 'BrownWarm', text: 'Brown Warm' },
          //   ],
          // },
          {
            name: 'allowedAudiences',
            component: Select,
            label: 'Allowed Audiences',
            options: audiences,
            frmCreate: true,
            multiple: true,
            required: true,
            validation: yup.array().min(1).required('Allowed Audiences are required'),
          },
          {
            name: 'allowedStyles',
            component: Select,
            label: 'Allowed Styles',
            options: styles,
            frmCreate: true,
            multiple: true,
            required: true,
            validation: yup.array().min(1).required('Allowed Styles are required'),
          },
          {
            name: 'version',
            component: Single,
            label: 'Version',
            required: true,
            validation: yup.string().required('version is required'),
          },
        ],
        onSubmit: (data) => {
          data.allowedAudiences = data?.allowedAudiences?.map((audience) => {
            return audience.split('#')[0];
          });
          data.allowedStyles = data.allowedStyles.map((audience) => {
            return audience.split('#')[0];
          });
          dispatch(action.createProduct(data, { page, pageSize: rowsPerPage, part: 'haircolor' }));
        },
        title: 'Create',
      },
      {
        name: 'editProduct',
        fields: [
          {
            name: 'part',
            autoFocus: true,
            component: Single,
            label: 'Part',
            required: true,
            readonly: true,
          },
          {
            name: 'name',
            autoFocus: true,
            component: Single,
            label: 'Name',
            required: true,
            validation: yup.string().required('Name is required'),
          },
          {
            name: 'range',
            autoFocus: true,
            component: Single,
            label: 'Range',
            required: true,
            validation: yup.string().required('Range is required'),
          },
          // {
          //   name: 'shadeUrl',
          //   component: Single,
          //   label: 'Shade URL',
          // },
          // {
          //   name: 'shadeName',
          //   component: Select,
          //   label: 'Natural Shade Name',
          //   options: [
          //     { value: '', text: '--' },
          //     { value: 'BlackBlack', text: 'Black Black' },
          //     { value: 'BlackBrown', text: 'Black Brown' },
          //     { value: 'BlondDark', text: 'Blond Dark' },
          //     { value: 'BlondLight', text: 'Blond Light' },
          //     { value: 'BrownChocolate', text: 'Brown Chocolate' },
          //     { value: 'BrownDark', text: 'Brown Dark' },
          //     { value: 'BrownLight', text: 'Brown Light' },
          //     { value: 'BrownWarm', text: 'Brown Warm' },
          //   ],
          // },
          {
            name: 'allowedAudiences',
            autoFocus: true,
            component: Select,
            multiple: true,
            frmEdit: true,
            options: audiences,
            label: 'Allowed Audiences',
            required: true,
            validation: yup.array().min(1).required('Allowed Audiences are required'),
          },
          {
            name: 'allowedStyles',
            autoFocus: true,
            component: Select,
            multiple: true,
            frmEdit: true,
            options: styles,
            label: 'Allowed Styles',
            required: true,
            validation: yup.array().min(1).required('Allowed Styles are required'),
          },
          {
            name: 'version',
            component: Single,
            label: 'Version',
            required: true,
            validation: yup.string().required('version is required'),
          },
        ],
        onSubmit: (data) => {
          data.allowedAudiences = formData.allowedAudiences;
          data.allowedStyles = formData.allowedStyles;
          dispatch(action.editProduct(data, 'index', { page, pageSize: rowsPerPage, part: 'haircolor' }));
        },
        title: 'Edit Haircolor',
      },
      {
        name: 'deleteProduct',
        contentText: 'Are you sure to delete the product?',
        fields: [
          {
            name: 'name',
            component: Single,
            label: 'Name',
            readonly: true,
          },
        ],
        onSubmit: ({ id }) => {
          dispatch(action.deleteProduct(id, { page, pageSize: rowsPerPage, part: 'haircolor' }));
        },
        title: 'Delete Haircolor',
      },
    ],
    title: 'Haircolor',
    toolbarItems: [
      {
        name: 'search',
        icon: Search,
        onChange: (e) => {
          setSearchValue(e.target.value);
        },
        onKeyUp: (e) => {
          e.keyCode === 13 &&
            searchValue.length >= 0 &&
            dispatch(
              action.fetchProducts({ page, pageSize: rowsPerPage, part: 'haircolor', name: searchValue.trim() }),
            );
        },
        text: 'Search',
        value: searchValue,
        type: 'text',
        clearValue: () => {
          setSearchValue('');
          dispatch(action.fetchProducts({ page, pageSize: rowsPerPage, part: 'haircolor' }));
        },
      },
      {
        name: 'refresh',
        icon: Refresh,
        onClick: () => {
          dispatch(action.fetchProducts({ page, pageSize: rowsPerPage, part: 'haircolor' }));
        },
        text: 'Refresh',
      },
      {
        name: 'create',
        icon: Add,
        onClick: () => {
          dispatch(
            action.openCreateProductModal({
              part: 'haircolor',
              name: '',
              range: '',
              // shadeUrl: '',
              // shadeName: '',
              allowedAudiences: [],
              allowedStyles: [],
              version: '',
            }),
          );
        },
        text: 'Create',
      },
    ],
  };

  return (
    <IndexPage
      config={config}
      data={data}
      setPage={(page) =>
        dispatch(action.fetchProducts({ page, pageSize: rowsPerPage, part: 'haircolor', search: searchValue }))
      }
      setRowsPerPage={(rowsPerPage) =>
        dispatch(action.fetchProducts({ page: 0, pageSize: rowsPerPage, part: 'haircolor', search: '' }))
      }
      total={total}
      page={page}
      rowsPerPage={rowsPerPage}
    />
  );
};

const mapStateToProps = ({ products, audiences, formData, styles }) => {
  return {
    data: products.results,
    formData: formData,
    audiences: audiences.results.map((item) => {
      return {
        value: item.id,
        text: item.name,
      };
    }),
    styles: styles.results.map((item) => {
      return {
        value: item.guid,
        text: item.name,
      };
    }),
    total: products.total,
    rowsPerPage: products.limit,
    page: products.page,
  };
};

export default connect(mapStateToProps)(HaircolorIndexPage);
