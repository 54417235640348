import { withStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as Icons from 'assets/images/index';
import { init, get, makeup, setSplitScreen, setPhotoMode, setCameraMode } from 'utils/ArMakeup';

const ArMakeup = withStyles(() => ({
  armakeup_container: {
    position: 'relative',
    height: '500px',
    minHeight: '500px',
    width: '500px',
    minWidth: '500px',
  },
  armakeup_toolbar: {
    position: 'absolute',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  armakeup_toolbar_hide: {
    display: 'none !important',
  },
  armakeup_icon: {
    marginLeft: '0.5rem',
    webkitFilter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',
    filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',
  },
  armakeup_label: {
    marginBottom: 0,
  },
  armakeup_hide: {
    display: 'none',
  },
  input_photo: {
    display: 'none',
    visibility: 'hidden',
  },
  armakeup_modal: {
    position: 'absolute',
    top: 0,
    zIndex: 99,
  },
  armakeup_close_button: {
    position: 'absolute',
    top: '16px',
    right: '16px',
    zIndex: 100,
    filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',
  },
  armakeup_loading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: 'white',
    stroke: '#3f51b5',
    zIndex: 1,
  },
}))(({ classes, formData, audienceScope, stylesConfig }) => {
  const [split, setSplit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [mode, setMode] = useState('photo');
  const [snapshotImage, setSnapshotImage] = useState(null);

  useEffect(() => {
    setLoading(true);
    init(audienceScope).then(() => {
      getPhotoImg(Icons.defaultImg).then((photoImg) => {
        makeup({
          mode: 'photo',
          photoImg,
          selected: {
            part: 'haircolor',
            style: stylesConfig,
            rgbColor: [formData.rgbColor[0], formData.rgbColor[1], formData.rgbColor[2]],
            strength: formData.strength,
          },
        }).then(({ mode }) => {
          setMode(mode);
          setLoading(false);
        });
      });
    });
  }, []);

  useEffect(() => {
    get().then(({ makeupInstance }) => {
      if (makeupInstance) {
        makeup({
          selected: {
            part: 'haircolor',
            style: stylesConfig,
            rgbColor: [formData.rgbColor[0], formData.rgbColor[1], formData.rgbColor[2]],
            strength: formData.strength,
          },
        });
      }
    });
  }, [formData]);

  const toggleSplitScreen = () => {
    setSplitScreen().then(({ split }) => {
      setSplit(split);
    });
  };

  const toPhotoMode = (img) => {
    setLoading(true);
    const file = img.currentTarget.files[0];
    if (file !== undefined) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        getPhotoImg(reader.result).then((photoImg) => {
          return setPhotoMode(photoImg).then(({ mode }) => {
            setMode(mode);
            setLoading(false);
          });
        });
      };
    }
  };

  const getPhotoImg = (img) => {
    return new Promise((resolve) => {
      const photoImg = new Image();
      photoImg.crossOrigin = 'anonymous';
      photoImg.onload = () => {
        const photo = document.getElementById('photo');
        photo.value = '';
        resolve(photoImg);
      };
      photoImg.src = img;
    });
  };

  const toggleToCamera = () => {
    setLoading(true);
    setCameraMode().then(({ mode }) => {
      setMode(mode);
      setLoading(false);
    });
  };

  const snapshot = () => {
    return get().then(({ glCanvas, width, height }) => {
      const dataURL = glCanvas.toDataURL();
      setSnapshotImage({ dataURL, width, height });
    });
  };

  return (
    <div className={classes.armakeup_container} id="armakeup">
      {loading && (
        <div className={classes.armakeup_loading}>
          <CircularProgress color="primary" />
        </div>
      )}
      <div className={classes.armakeup_toolbar}>
        {mode === 'photo' && <img className={classes.armakeup_icon} src={Icons.cameraMode} onClick={toggleToCamera} />}
        <label htmlFor="photo" className={classes.armakeup_label}>
          <img className={classes.armakeup_icon} src={Icons.photoMode} />
        </label>
        {split ? (
          <img className={classes.armakeup_icon} src={Icons.fullScreen} onClick={toggleSplitScreen} />
        ) : (
          <img className={classes.armakeup_icon} src={Icons.splitScreen} onClick={toggleSplitScreen} />
        )}
        <img className={classes.armakeup_icon} src={Icons.download} onClick={snapshot} />
        <input
          id="photo"
          className={classes.input_photo}
          onChange={(e) => toPhotoMode(e)}
          type="file"
          accept="image/*"
        />
        {snapshotImage && (
          <div className={classes.armakeup_modal}>
            <img src={snapshotImage.dataURL} width={snapshotImage.width} height={snapshotImage.height} />
            <img src={Icons.cancel} className={classes.armakeup_close_button} onClick={() => setSnapshotImage(null)} />
          </div>
        )}
      </div>
    </div>
  );
});

const mapStateToProps = ({ formData, product, audienceScope, stylesConfig }) => ({
  formData,
  product,
  audienceScope,
  stylesConfig,
});

export default connect(mapStateToProps)(ArMakeup);
