import { combineReducers } from 'redux';
import alert from 'redux/reducers/alert';
import audience from 'redux/reducers/audience';
import audiences from 'redux/reducers/audiences';
import audienceScope from 'redux/reducers/audienceScope';
import formData from 'redux/reducers/formData';
import loading from 'redux/reducers/loading';
import loginInfo from 'redux/reducers/loginInfo';
import openingModal from 'redux/reducers/openingModal';
import product from 'redux/reducers/product';
import products from 'redux/reducers/products';
import sdkVersions from 'redux/reducers/sdkVersions';
import style from 'redux/reducers/style';
import styles from 'redux/reducers/styles';
import stylesConfig from 'redux/reducers/stylesConfig';
import shades from 'redux/reducers/shades';
import sdk from 'redux/reducers/sdk';
import dashboard from 'redux/reducers/dashboard';

export default combineReducers({
  shades,
  alert,
  audience,
  audiences,
  audienceScope,
  formData,
  loading,
  loginInfo,
  openingModal,
  product,
  products,
  sdkVersions,
  style,
  styles,
  stylesConfig,
  sdk,
  dashboard,
});
