import * as api from 'api/product';
import * as action from 'redux/actions';
import { productShadesInitFormData } from 'utils';

//product
export const createProduct = (data, pageParams) => (dispatch) => {
  dispatch(action.closeModal());
  dispatch(action.showLoading());
  api
    .createProduct(data)
    .then(() => {
      api.getProducts(pageParams).then((response) => {
        dispatch(action.setProducts(response.data));
      });
    })
    .then(() => {
      dispatch(action.setSuccess('The project has been created.'));
    })
    .catch((error) => {
      dispatch(action.setError(error));
    })
    .finally(() => {
      dispatch(action.hideLoading());
    });
};

export const deleteProduct = (id, pageParams, resolve) => (dispatch) => {
  dispatch(action.closeModal());
  dispatch(action.showLoading());
  api.deleteProduct(id).then(() => {
    if (resolve) {
      return resolve();
    }
  });
  return api
    .getProducts(pageParams)
    .then((response) => {
      dispatch(action.setStylesPage(pageParams.page));
      dispatch(action.setProducts(response.data));
    })
    .then(() => {
      dispatch(action.setSuccess('The product has been deleted'));
    })
    .catch((error) => {
      dispatch(action.setError(error));
    })
    .finally(() => {
      dispatch(action.hideLoading());
    });
};

export const editProduct =
  (data, context = null, pageParams) =>
  (dispatch) => {
    dispatch(action.closeModal());
    dispatch(action.showLoading());
    api
      .editProduct(data)
      .then(() => {
        if (context === 'details') {
          return api.getProduct(data.id).then((response) => {
            dispatch(action.setProduct(response.data));
          });
        } else {
          return api.getProducts(pageParams).then((response) => {
            dispatch(action.setProducts(response.data));
          });
        }
      })
      .then(() => {
        dispatch(action.setSuccess('The product has been updated.'));
      })
      .catch((error) => {
        dispatch(action.setError(error));
      })
      .finally(() => {
        dispatch(action.hideLoading());
      });
  };

//shade
export const editProductShade =
  (data, context = null) =>
  (dispatch) => {
    dispatch(action.closeModal());
    dispatch(action.showLoading());

    api
      .editProductShade(data)
      .then(() => {
        if (context === 'details') {
          return api.getProduct(data.id);
        } else {
          return api.getProducts();
        }
      })
      .then((response) => {
        if (context === 'details') {
          dispatch(action.setProduct(response.data));
        } else {
          dispatch(action.setProducts(response.data));
        }
      })
      .then(() => {
        dispatch(action.setSuccess('Shades updated successfully.'));
      })
      .catch((error) => {
        dispatch(action.setError(error));
      })
      .finally(() => {
        dispatch(action.hideLoading());
      });
  };

const setShadesVersionList = (data) => {
  const { shadesVersion, releasedShadesVersion } = data;
  return shadesVersion
    ? Object.keys(shadesVersion).map((shade) => {
        const { ...rest } = shadesVersion[shade].shades[0];
        return {
          version: shade,
          releasedShadesVersion: releasedShadesVersion || '',
          createdDate: shadesVersion[shade].createdDate,
          ...rest,
        };
      })
    : [];
};

//product fetch
export const fetchProduct = (id) => (dispatch) => {
  dispatch(action.showLoading());
  api
    .getProduct(id)
    .then((response) => {
      // format shades version list
      response.data.shadesVersionLists = setShadesVersionList(response.data);
      dispatch(action.setProduct(response.data));
      dispatch(
        action.setShades({ total: response.data?.shadesVersion ? Object.keys(response.data.shadesVersion).length : 0 }),
      );
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      dispatch(action.setError(error));
    })
    .finally(() => {
      dispatch(action.hideLoading());
    });
};

export const fetchProducts = (pageParams) => (dispatch) => {
  dispatch(action.showLoading());
  api
    .getProducts(pageParams)
    .then((response) => {
      const page = Math.ceil(response.data.offset / response.data.limit);
      dispatch(action.setProducts(response.data));
      dispatch(action.setProductsPage(page));
      return Promise.resolve();
    })
    .catch((error) => {
      dispatch(action.setError(error));
    })
    .finally(() => {
      dispatch(action.hideLoading());
    });
};

//open modal
export const openCreateProductModal = (data) => (dispatch) => {
  dispatch(action.setFormData(data));
  dispatch(action.openModal('createProduct'));
};

export const openEditProductModal = (id) => (dispatch) => {
  dispatch(action.showLoading());
  api
    .getProduct(id)
    .then((response) => {
      const responseData = { ...response.data };
      dispatch(action.setFormData(responseData));
      dispatch(action.openModal('editProduct'));
      return Promise.resolve();
    })
    .catch((error) => {
      dispatch(action.setError(error));
    })
    .finally(() => {
      dispatch(action.hideLoading());
    });
};

export const openDeleteProductModal = (data) => (dispatch) => {
  dispatch(action.setFormData(data));
  dispatch(action.openModal('deleteProduct'));
};

export const openReleaseProductModal = (id) => (dispatch) => {
  dispatch(action.showLoading());
  api
    .getProduct(id)
    .then((response) => {
      dispatch(action.setFormData(response.data));
      dispatch(action.openModal('releaseProduct'));
      return Promise.resolve();
    })
    .catch((error) => {
      dispatch(action.setError(error));
    })
    .finally(() => {
      dispatch(action.hideLoading());
    });
};

export const openEditProductShadeModal = (id) => {
  const productId = id.split('#')[0];
  const index = id.split('#')[1];

  return (dispatch) => {
    dispatch(action.showLoading());
    api
      .getProductShades(productId)
      .then((response) => {
        let shades = {};
        Object.keys(response.data?.shadesVersion).map((version, key) => {
          if (key === parseInt(index)) {
            shades = response.data.shadesVersion[version].shades[0];
          }
        });
        dispatch(action.setFormData(shades));
        dispatch(action.setSelectedShadesIndex(index));
        dispatch(action.openModal('createProductShade'));
        return Promise.resolve();
      })
      .catch((error) => {
        dispatch(action.setError(error));
      })
      .finally(() => {
        dispatch(action.hideLoading());
      });
  };
};

//shades
export const createProductShade =
  (data, context = null) =>
  (dispatch) => {
    dispatch(action.closeModal());
    dispatch(action.showLoading());

    api
      .createProductShade(data)
      .then(() => {
        if (context === 'details') {
          return api.getProduct(data.id);
        } else {
          return api.getProducts();
        }
      })
      .then((response) => {
        if (context === 'details') {
          response.data.shadesVersionLists = setShadesVersionList(response.data);
          dispatch(action.setProduct(response.data));
        } else {
          dispatch(action.setProducts(response.data));
        }
      })
      .then(() => {
        dispatch(action.setSuccess('New shade have been created.'));
      })
      .catch((error) => {
        dispatch(action.setError(error));
      })
      .finally(() => {
        dispatch(action.hideLoading());
      });
  };

export const releaseProductShade =
  (data, context = null) =>
  (dispatch) => {
    dispatch(action.closeModal());
    dispatch(action.showLoading());
    api
      .releaseProductShade(data)
      .then(() => {
        if (context === 'details') {
          return api.getProduct(data.id);
        } else {
          return api.getProducts();
        }
      })
      .then((response) => {
        if (context === 'details') {
          response.data.shadesVersionLists = setShadesVersionList(response.data);
          dispatch(action.setProduct(response.data));
        } else {
          dispatch(action.setProducts(response.data));
        }
      })
      .then(() => {
        dispatch(action.setSuccess('The Shade have been released.'));
      })
      .catch((error) => {
        dispatch(action.setError(error));
      })
      .finally(() => {
        dispatch(action.hideLoading());
      });
  };

export const openCreateProductShadeModal = (id, index) => {
  return (dispatch) => {
    dispatch(action.showLoading());
    api
      .getProduct(id)
      .then((response) => {
        const shadesVersion = response.data?.shadesVersion;
        let shadesData = productShadesInitFormData(response.data.part)[0];
        shadesData.shadesVersion = '';

        if (shadesVersion) {
          const selectedShadesVersion = Object.keys(shadesVersion)[index];
          const releasedShadesVersion = response.data.releasedShadesVersion;

          // ReNew Shades Modal
          if (index >= 0 && shadesVersion) {
            shadesData = Object.assign(shadesVersion[selectedShadesVersion].shades[0], {
              shadesVersion: selectedShadesVersion,
            });
          } else if (shadesVersion && releasedShadesVersion) {
            shadesData = Object.assign(shadesVersion[releasedShadesVersion].shades[0], {
              shadesVersion: releasedShadesVersion,
            });
          } else if (shadesVersion) {
            const shadesVersionKeys = Object.keys(shadesVersion);
            const lastShadesVersion = shadesVersionKeys[shadesVersionKeys.length - 1];
            shadesData = Object.assign(shadesVersion[lastShadesVersion].shades[0], {
              shadesVersion: lastShadesVersion,
            });
          }
        }

        dispatch(action.setFormData(shadesData));
        dispatch(action.openModal('createProductShade'));
        return Promise.resolve();
      })
      .catch((error) => {
        dispatch(action.setError(error));
      })
      .finally(() => {
        dispatch(action.hideLoading());
      });
  };
};

export const openReleaseProductShadeModal = (id) => {
  return (dispatch) => {
    dispatch(action.showLoading());
    api
      .getProduct(id)
      .then((response) => {
        if (response.data.shadesVersion) {
          const shadesVersion = response.data.shadesVersion;
          const releasedShadesVersion = response.data.releasedShadesVersion;
          let shadesData = productShadesInitFormData(response.data.part)[0];
          shadesData.shadesVersion = '';

          const setShadesData = (shadesVersionKeys) => {
            const lastShadesVersion = shadesVersionKeys[shadesVersionKeys.length - 1];
            shadesData = Object.assign(shadesVersion[lastShadesVersion].shades[0], {
              shadesVersion: lastShadesVersion,
            });
          };

          if (shadesVersion && releasedShadesVersion) {
            setShadesData(Object.keys(shadesVersion).filter((version) => version !== releasedShadesVersion));
          } else if (shadesVersion) {
            setShadesData(Object.keys(shadesVersion));
          }
          dispatch(action.setFormData(shadesData));
          dispatch(action.openModal('releaseProductShade'));
        }
        return Promise.resolve();
      })
      .catch((error) => {
        dispatch(action.setError(error));
      })
      .finally(() => {
        dispatch(action.hideLoading());
      });
  };
};

//action
export const resetProduct = () => ({ type: 'SET_PRODUCT', payload: {} });

export const setProduct = (product) => ({
  type: 'SET_PRODUCT',
  payload: product,
});

export const setProducts = (products) => {
  return {
    type: 'SET_PRODUCTS',
    payload: products,
  };
};

export const setProductsPage = (page) => ({
  type: 'SET_PRODUCTS_PAGE',
  payload: page,
});
