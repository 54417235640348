import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip as MuiTooltip,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { FirstPage, KeyboardArrowLeft, KeyboardArrowRight, LastPage } from '@material-ui/icons';
import React from 'react';
import { Link } from 'react-router-dom';

const Tooltip = withStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
  },
}))(({ classes, ...rest }) => {
  return <MuiTooltip arrow classes={classes} {...rest} />;
});

const rowsPerPageOptions = [10, 20, 50];

const TablePaginationActions = withStyles((theme) => ({
  footer: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(2.5),
  },
}))(({ classes, count, page, rowsPerPage, onPageChange }) => {
  function handleFirstPageButtonClick(event) {
    onPageChange(event, 0);
  }

  function handleBackButtonClick(event) {
    onPageChange(event, page - 1);
  }

  function handleNextButtonClick(event) {
    onPageChange(event, page + 1);
  }

  function handleLastPageButtonClick(event) {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  }

  return (
    <div className={classes.footer}>
      <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
        <FirstPage />
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        <LastPage />
      </IconButton>
    </div>
  );
});

export default withStyles((theme) => ({
  root: {
    width: '100%',
    overflowX: 'auto',
    marginTop: theme.spacing(3),
  },
  table: {
    minWidth: 650,
  },
  head: {
    backgroundColor: theme.palette.background.paper,
    fontSize: '0.9rem',
    height: theme.spacing(5.5),
  },
  leftTitle: {
    backgroundColor: theme.palette.background.paper,
    fontSize: '0.9rem',
    height: theme.spacing(5.5),
    paddingLeft: 30,
  },
  cell: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  action: {
    margin: theme.spacing(0),
  },
  releasedShadesVersion: {
    background: '#89bf04',
    color: '#ffffff',
    borderRadius: theme.spacing(0.5),
    padding: '3px',
  },
}))(({ classes, columns, data, ...rest }) => {
  function handleChangePage(event, newPage) {
    rest.setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    rest.setRowsPerPage(parseInt(event.target.value, 10));
  }

  return (
    <Paper className={classes.root}>
      <Table className={classes.table} stickyHeader size="small">
        <TableHead>
          <TableRow>
            {columns.map((column) => {
              return (
                <TableCell
                  className={column.name === 'action' ? classes.leftTitle : classes.head}
                  align={column.align || 'left'}
                  key={column.name}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.text}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <TableRow key={row.id}>
              {columns.map(
                ({ actions, align, component: Component, maxLength, name, path, refName, target, ...rest }) => (
                  <TableCell className={classes.cell} align={align || 'left'} key={name}>
                    {actions ? (
                      actions.map(({ icon, iconAlt, name, onClick, path, target, title, titleAlt }) => {
                        const Icon = iconAlt && !row[name] ? iconAlt : icon;
                        if (onClick) {
                          return (
                            <Tooltip arrow key={name} title={titleAlt && !row[name] ? titleAlt : title}>
                              <IconButton
                                aria-label={name}
                                className={classes.action}
                                key={name}
                                onClick={() => onClick(row, index)}
                              >
                                {<Icon fontSize="small" />}
                              </IconButton>
                            </Tooltip>
                          );
                        } else if (path) {
                          return (
                            <Tooltip arrow key={name} title={title}>
                              <IconButton
                                aria-label={name}
                                className={classes.action}
                                component={Link}
                                to={`${path}/${row.id}`}
                                target={target}
                              >
                                {<Icon fontSize="small" />}
                              </IconButton>
                            </Tooltip>
                          );
                        } else {
                          return null;
                        }
                      })
                    ) : path ? (
                      <Link to={`${path}/${row[refName]}`} target={target}>
                        {row[name]}
                      </Link>
                    ) : Component ? (
                      <Component id={row[name]} {...rest} data={row} field={{ ...rest, name: name }} />
                    ) : maxLength ? (
                      row[name].substring(0, maxLength) && row[name].length > maxLength ? (
                        row[name] + '...'
                      ) : (
                        row[name]
                      )
                    ) : row[name] ? (
                      row[name] === '' ? (
                        '-'
                      ) : name === 'version' && row.releasedShadesVersion === row[name] ? (
                        <div>
                          {row[name]} <span className={classes.releasedShadesVersion}>released</span>
                        </div>
                      ) : (
                        row[name]
                      )
                    ) : (
                      '-'
                    )}
                  </TableCell>
                ),
              )}
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={rowsPerPageOptions}
              colSpan={columns.length}
              count={rest.total}
              page={rest.page}
              rowsPerPage={rest.rowsPerPage}
              SelectProps={{
                inputProps: { 'aria-label': 'rows per page' },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
              labelRowsPerPage="Rows"
            />
          </TableRow>
        </TableFooter>
      </Table>
    </Paper>
  );
});
