import { del, get, post, put } from 'api';
// import * as _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';

export const createProduct = (data) => {
  // const allowedAudiences = _.split(data.allowedAudiences, '\n').map((item) => item.trim());
  // const allowedStyles = _.split(data.allowedStyles, '\n').map((item) => item.trim());
  return post('/products', { guid: uuidv4().toUpperCase(), ...data });

  // if (process.env.NODE_ENV !== 'production') {
  //   const uuidgen = uuidv4();
  //   mockupProducts.push({
  //     id: uuidgen,
  //     guid: uuidgen,
  //     'version': 1,
  //     releaseDate: '',
  //     'shades': [
  //       {
  //         'color': [0, 0, 0],
  //         'strength': 0,
  //       },
  //     ],
  //     ...data,
  //   });
  //   return Promise.resolve();
  // }
  // return put('/product/', data);
};

export const deleteProduct = (id) => {
  // if (process.env.NODE_ENV !== 'production') {
  //   _.remove(mockupProducts, { guid: id });
  //   return Promise.resolve();
  // }
  return del(`/products/${id}`);
};

export const editProduct = ({ id, ...rest }) => {
  // const allowedAudiences = _.split(rest.allowedAudiences, '\n').map((item) => item.trim());
  // const allowedStyles = _.split(rest.allowedStyles, '\n').map((item) => item.trim());
  return put(`/products/${id}`, { ...rest });
};

export const editProductShade = ({ id, shades }) => {
  return put(`/products/${id}/shades`, shades);
};

export const getProductShades = (id) => {
  return get(`/products/${id}`);
};

export const releaseProductShade = ({ id, shadesVersion }) => {
  return post(`/products/${id}/shades/${shadesVersion}`);
};

export const getProduct = (id) => {
  return get(`/products/${id}`);
};

// export const createProduct = (data) => {
//   console.log(...{ data });
//   // return post('/213213', data);
// };

// export const editProduct = ({ id, ...rest }) => {
//   console.log(id, { ...rest });
//   // return post(`${id}`, { ...rest });
// };

// export const deleteProduct = ({ id }) => {
//   console.log(id);
//   // return post(`${id}`);
// };

// export const getProduct = () => {
//   return get('products');
// };

export const getProducts = (pageParams) => {
  const query = pageParams
    ? `?page=${pageParams.page ? pageParams.page + 1 : 1}&pageSize=${pageParams.pageSize ? pageParams.pageSize : 10}${
        pageParams.part !== undefined && pageParams.part !== '' ? '&part=' + pageParams.part : ''
      }${pageParams.name !== undefined && pageParams.name !== '' ? '&name=' + pageParams.name : ''} `
    : '';
  return get(`/products${query}`);
};

export const fetchProductAmount = () => {
  return get(`/products/amount`);
};

export const createProductShade = ({ id, shades }) => {
  return post(`/products/${id}/shades`, shades);
};
