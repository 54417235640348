import { Add, Delete, Edit, Refresh, ToggleOn } from '@material-ui/icons';
import ColorAvatar from 'components/ColorAvatar/ColorAvatar';
import Select from 'components/Form/Select';
import Single from 'components/Form/Single';
import SimpleListTab from 'components/SimpleListTab';
import DetailsPage from 'pages/DetailsPage';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import * as action from 'redux/actions';
import * as yup from 'yup';
import RgbSlider from '../../components/ColorSlider/RgbSlider';
import SingleSlider from '../../components/ColorSlider/SingleSlider';
import LocalDate from 'components/Form/LocalDate';

const HaircolorDetailPage = ({
  data,
  dispatch,
  formData,
  audiences,
  audienceScope,
  createShadesVersionOptions,
  releaseShadesVersionOptions,
  rowsPerPage,
  styles,
  page,
  total,
}) => {
  const history = useHistory();
  const { id } = useParams();

  useEffect(() => {
    dispatch(action.fetchAudiences({ pageSize: 1000 }));
    dispatch(action.fetchStyles({ pageSize: 1000, part: 'haircolor' }));
    dispatch(action.fetchProduct(id));
    dispatch(action.fetchAudienceScope({ search: 'DXT' }));
    dispatch(action.fetchStylesConfig({ part: 'haircolor' }));
    return () => {
      dispatch(action.resetProduct());
    };
  }, [dispatch, audienceScope, id]);

  const config = {
    category: 'masterdata',
    categoryHierarchy: ['materdata', 'products', 'haircolor'],
    modals: [
      {
        name: 'editProduct',
        fields: [
          {
            name: 'part',
            autoFocus: true,
            component: Single,
            label: 'Part',
            required: true,
            readonly: true,
          },
          {
            name: 'name',
            autoFocus: true,
            component: Single,
            label: 'Name',
            required: true,
            validation: yup.string().required('Name is required'),
          },
          {
            name: 'range',
            autoFocus: true,
            component: Single,
            label: 'Range',
            required: true,
            validation: yup.string().required('Range is required'),
          },
          // {
          //   name: 'shadeUrl',
          //   component: Single,
          //   label: 'Shade URL',
          // },
          // {
          //   name: 'shadeName',
          //   component: Select,
          //   label: 'Natural Shade Name',
          //   options: [
          //     { value: '', text: '--' },
          //     { value: 'BlackBlack', text: 'Black Black' },
          //     { value: 'BlackBrown', text: 'Black Brown' },
          //     { value: 'BlondDark', text: 'Blond Dark' },
          //     { value: 'BlondLight', text: 'Blond Light' },
          //     { value: 'BrownChocolate', text: 'Brown Chocolate' },
          //     { value: 'BrownDark', text: 'Brown Dark' },
          //     { value: 'BrownLight', text: 'Brown Light' },
          //     { value: 'BrownWarm', text: 'Brown Warm' },
          //   ],
          // },
          {
            name: 'allowedAudiences',
            autoFocus: true,
            component: Select,
            multiple: true,
            frmEdit: true,
            options: audiences,
            label: 'Allowed Audiences',
            required: true,
            validation: yup.array().min(1).required('Allowed Audiences are required'),
          },
          {
            name: 'allowedStyles',
            autoFocus: true,
            component: Select,
            multiple: true,
            frmEdit: true,
            options: styles,
            label: 'Allowed Styles',
            required: true,
            validation: yup.array().min(1).required('Allowed Styles are required'),
          },
          {
            name: 'version',
            component: Single,
            label: 'Version',
            required: true,
            validation: yup.string().required('version is required'),
          },
        ],
        onSubmit: (submitData) => {
          submitData.allowedAudiences = formData.allowedAudiences;
          submitData.allowedStyles = formData.allowedStyles;
          dispatch(action.editProduct(submitData, 'details'));
        },
        title: 'Edit Haircolor',
      },
      {
        name: 'deleteProduct',
        contentText: 'Are you sure to delete the product?',
        fields: [
          {
            name: 'name',
            component: Single,
            label: 'Name',
            readonly: true,
          },
        ],
        onSubmit: () => {
          dispatch(
            action.deleteProduct(id, {}, () => {
              history.push('/haircolor');
            }),
          );
        },
        title: 'Delete',
      },
      {
        name: 'releaseProduct',
        contentText: `Are you sure to ${data.releaseDate === '' ? 'release' : 'unreleaseable'} the product?`,
        fields: [
          {
            name: 'name',
            component: Single,
            label: 'Name',
            readonly: true,
          },
        ],
        onSubmit: (data) => {
          dispatch(
            action.editProduct({ ...data, releaseDate: data.releaseDate === '' ? new Date().toJSON() : '' }, 'details'),
          );
        },
        title: 'Release Product',
      },
      {
        name: 'createProductShade',
        fields: [
          {
            label: 'Shades Version',
            name: 'shadesVersion',
            options: createShadesVersionOptions,
            component: Select,
            span: 8,
          },
          {
            name: 'rgbColor',
            component: RgbSlider,
            span: 8,
          },
          {
            name: 'strength',
            component: SingleSlider,
            label: 'Strength',
            maxValue: 100,
            minValue: 0,
            stepValue: 1,
            value: formData.strength,
            span: 8,
          },
        ],
        onSubmit: () => {
          // delete formData.shadesVersion;
          // delete formData.audiences;
          // delete formData.gery
          // const { rgbColor, strength } = formData;
          // console.log({ id: data.id, shades: [{rgbColor, strength}] });
          // const { ...restData } = formData;
          delete formData.shadesVersion;
          dispatch(action.createProductShade({ id: data.id, shades: [formData] }, 'details'));
        },
        title: 'Create Shade',
        part: 'haircolor',
      },
      {
        name: 'releaseProductShade',
        fields: [
          {
            label: 'Shades Version',
            name: 'shadesVersion',
            options: releaseShadesVersionOptions,
            component: Select,
            span: 8,
          },
          {
            name: 'rgbColor',
            component: RgbSlider,
            disabled: true,
            span: 8,
          },
          {
            name: 'strength',
            component: SingleSlider,
            disabled: true,
            label: 'Strength',
            maxValue: 100,
            minValue: 0,
            stepValue: 1,
            value: formData.strength,
            span: 8,
          },
        ],
        onSubmit: () => {
          dispatch(action.releaseProductShade({ id: data.id, shadesVersion: formData.shadesVersion }, 'details'));
        },
        title: 'Release Shade',
        part: 'haircolor',
      },
      // {
      //   name: 'editProductShade',
      //   fields: [
      //     {
      //       name: 'rgbColor',
      //       component: RgbSlider,
      //     },
      //     {
      //       name: 'strength',
      //       component: SingleSlider,
      //       label: 'Strength',
      //       maxValue: 100,
      //       minValue: 0,
      //       stepValue: 1,
      //       value: formData.strength,
      //     },
      //   ],
      //   onSubmit: () => {
      //     const { ...restData } = formData;
      //     // const index = 0;
      //     // const { shades, ...restProduct } = data;
      //     // const newShades = shades.slice(0);
      //     // newShades[index] = restData;
      //     // restProduct.index = index;
      //     dispatch(action.createProductShade({ id: data.id, shades: Object.values(restData) }, 'details'));
      //   },
      //   title: 'Edit Shade',
      //   part: 'haircolor',
      // },
    ],
    tabs: [
      {
        name: 'general',
        label: 'General',
        fields: [
          { name: 'guid', label: 'Guid' },
          { name: 'part', label: 'Part' },
          { name: 'name', label: 'Name' },
          { name: 'range', label: 'Range' },
          // { name: 'shadeUrl', label: 'Shade URL' },
          // { name: 'shadeName', label: 'Natural Shade Name' },
          { name: 'allowedAudiences', label: 'Allowed Audiences' },
          { name: 'allowedStyles', label: 'Allowed Styles' },
          { name: 'version', label: 'Version' },
          { name: 'createdDate', label: 'Created Date', component: LocalDate },
          { name: 'lastModifiedDate', label: 'Last Modified Date', component: LocalDate },
        ],
      },
      {
        name: 'shadesVersionLists',
        label: 'Shades',
        component: SimpleListTab,
        columns: [
          {
            name: 'action',
            text: 'Actions',
            actions: [
              {
                name: 'shadesEdit',
                icon: Edit,
                onClick: (row, index) => {
                  // const index = data.shades.indexOf(row);
                  // const indexId = id + '#' + index;
                  // dispatch(action.setSelectedShadesIndex(index));
                  dispatch(action.openCreateProductShadeModal(id, index));
                },
                title: 'Shades Edit',
              },
            ],
          },
          { name: 'version', text: 'Version', minWidth: 200 },
          { name: 'rgbColor', component: ColorAvatar, text: 'RgbColor', minWidth: 200 },
          { name: 'strength', text: 'Strength', minWidth: 200 },
          { name: 'createdDate', component: LocalDate, text: 'Created Date', minWidth: 200 },
        ],
      },
    ],
    title: 'Haircolor Details',
    toolbarItems: [
      {
        tabName: 'general',
        items: [
          {
            name: 'refresh',
            icon: Refresh,
            onClick: () => {
              dispatch(action.fetchProduct(id));
            },
            text: 'Refresh',
          },
          {
            name: 'edit',
            icon: Edit,
            onClick: () => {
              dispatch(action.openEditProductModal(id));
            },
            text: 'Edit',
          },
          {
            name: 'delete',
            icon: Delete,
            onClick: () => {
              dispatch(action.openDeleteProductModal(data));
            },
            text: 'Delete',
          },
        ],
      },
      {
        tabName: 'shades',
        items: [
          {
            name: 'refresh',
            icon: Refresh,
            onClick: () => {
              dispatch(action.fetchProduct(id));
            },
            text: 'Refresh',
          },
          {
            name: 'createShades',
            icon: Add,
            onClick: () => {
              dispatch(action.openCreateProductShadeModal(id));
            },
            text: 'Create',
          },
          {
            name: 'releaseShades',
            icon: ToggleOn,
            onClick: () => {
              dispatch(action.openReleaseProductShadeModal(id));
            },
            text: 'Release',
          },
        ],
      },
    ],
  };

  if (audienceScope === 'hair/0.5.2' || audienceScope === 'hair/0.8.4' || audienceScope === 'hair/0.9.7') {
    for (let i = 0; i < config.tabs.length; i++) {
      if (config.tabs[i].name === 'shadesVersion') {
        for (let c = 0; c < config.tabs[i].columns.length; c++) {
          if (config.tabs[i].columns[c].name === 'grey') {
            config.tabs[i].columns.splice(c, 1);
            c--;
          }
          if (config.tabs[i].columns[c].name === 'shining') {
            config.tabs[i].columns.splice(c, 1);
            c--;
          }
        }
      }
    }
    // for (let i = 0; i < config.modals.length; i++) {
    //   if (config.modals[i].name === 'createProductShade') {
    //     for (let f = 0; f < config.modals[i].fields.length; f++) {
    //       if (config.modals[i].fields[f].name === 'grey') {
    //         config.modals[i].fields.splice(f, 1);
    //         f--;
    //       }
    //       if (config.modals[i].fields[f].name === 'shining') {
    //         config.modals[i].fields.splice(f, 1);
    //         f--;
    //       }
    //     }
    //   }
    // }
    // for (let i = 0; i < config.modals.length; i++) {
    //   if (config.modals[i].name === 'releaseProductShade') {
    //     for (let f = 0; f < config.modals[i].fields.length; f++) {
    //       if (config.modals[i].fields[f].name === 'grey') {
    //         config.modals[i].fields.splice(f, 1);
    //         f--;
    //       }
    //       if (config.modals[i].fields[f].name === 'shining') {
    //         config.modals[i].fields.splice(f, 1);
    //         f--;
    //       }
    //     }
    //   }
    // }
  }

  return (
    <DetailsPage
      config={config}
      data={data}
      audiences={audiences}
      styles={styles}
      total={total}
      page={page}
      rowsPerPage={rowsPerPage}
    />
  );
};

const mapStateToProps = ({ audiences, audienceScope, product, formData, shades, styles }) => ({
  formData: formData,
  audienceScope: audienceScope,
  audiences: audiences.results.map((item) => {
    return {
      value: item.id,
      text: item.name,
    };
  }),
  styles: styles.results.map((item) => {
    return {
      value: item.guid,
      text: item.name,
    };
  }),
  data: product,
  total: shades.total,
  rowsPerPage: shades.total,
  page: 0,
  createShadesVersionOptions:
    (product?.shadesVersion &&
      Object.keys(product.shadesVersion).map((key) => {
        return {
          'value': key,
          'text': `${key} - ${
            new Date(product?.shadesVersion[key].createdDate).toLocaleDateString() +
            ', ' +
            new Date(product?.shadesVersion[key].createdDate).toLocaleTimeString()
          }`,
        };
      })) ||
    [],
  releaseShadesVersionOptions:
    (product?.shadesVersion &&
      Object.keys(product.shadesVersion)
        .filter((shade) => shade !== product?.releasedShadesVersion)
        .map((key) => {
          return {
            'value': key,
            'text': `${key} - ${
              new Date(product?.shadesVersion[key].createdDate).toLocaleDateString() +
              ', ' +
              new Date(product?.shadesVersion[key].createdDate).toLocaleTimeString()
            }`,
          };
        })) ||
    [],
});

export default connect(mapStateToProps)(HaircolorDetailPage);
