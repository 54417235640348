// // import DateFnsUtils from '@date-io/date-fns';
// import Paper from '@material-ui/core/Paper';
// import { withStyles } from '@material-ui/core/styles';
// import { Refresh } from '@material-ui/icons';
// // import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
// import 'date-fns';
// import { sub } from 'date-fns';
import DefaultLayout from 'layouts/DefaultLayout';
// import * as action from 'redux/actions';
import Breadcrumb from '../../components/Breadcrumb/BreadCrumb';
import Button from '@material-ui/core/Button';
// import { PowerBIEmbed } from 'powerbi-client-react';
// import DynamicRequestBoard from './DynamicRequestBoard';
// import Panel from './Panel';
// // import ArrowDropDownIcon from '@material-ui/icons';
// import FormControl from '@material-ui/core/FormControl';
// import InputLabel from '@material-ui/core/InputLabel';
// import NativeSelect from '@material-ui/core/NativeSelect';
// import { PowerBIEmbed } from 'powerbi-client-react';

// import {
//   ArcElement,
//   BarElement,
//   CategoryScale,
//   Chart as ChartJS,
//   Legend,
//   LineElement,
//   LinearScale,
//   PointElement,
//   Title,
//   Tooltip,
// } from 'chart.js';
// import { Bar, Line } from 'react-chartjs-2';
// import { connect } from 'react-redux';

// ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, BarElement, ArcElement, Title, Tooltip, Legend);
// const DashboardPage = withStyles((theme) => ({
//   root: {
//     backgroundColor: '#F2F3F5',
//     display: 'flex',
//     flexDirection: 'column',
//   },
//   panelFirstColumn: {
//     display: 'flex',
//     flexDirection: 'row',
//     flexWrap: 'nowrap',
//     alignContent: 'center',
//     justifyContent: 'space-between',
//     alignItems: 'center',
//     width: '100%',
//     height: '40vh',
//   },
//   datePickerRoot: {
//     color: 'rgb(128,128,128)',
//   },
//   button: {
//     margin: theme.spacing(2, 2, 2, 0),
//   },
//   dateFilter: {
//     width: '200px',
//   },
// }))(({ dispatch, classes, top, last, audience }) => {
//   const selectDateFilterMapping = {
//     lastHour: 'Last Hour',
//     lastDay: 'Last Day',
//     lastWeek: 'Last Week',
//     last2Week: 'Last 2 Weeks',
//     lastMonth: 'Last Month',
//     lastThreeMonth: 'Last 3 Months',
//     lastYear: 'Last Year',
//   };

//   const generateDateRange = (lastName, currentTime) => {
//     let startDate = '';
//     switch (lastName) {
//       case 'lastHour':
//         startDate = sub(currentTime, { hours: 1 });
//         return { startDate: startDate, endDate: currentTime, filterName: 'lastHour' };
//       case 'lastDay':
//         startDate = sub(currentTime, { days: 1 });
//         return { startDate: startDate, endDate: currentTime, filterName: 'lastDay' };
//       case 'lastWeek':
//         startDate = sub(currentTime, { weeks: 1 });
//         return { startDate: startDate, endDate: currentTime, filterName: 'lastWeek' };
//       case 'last2Week':
//         startDate = sub(currentTime, { weeks: 2 });
//         return { startDate: startDate, endDate: currentTime, filterName: 'last2Week' };
//       case 'lastMonth':
//         startDate = sub(currentTime, { months: 1 });
//         return { startDate: startDate, endDate: currentTime, filterName: 'lastMonth' };
//       case 'lastThreeMonth':
//         startDate = sub(currentTime, { months: 3 });
//         return { startDate: startDate, endDate: currentTime, filterName: 'lastThreeMonth' };
//       case 'lastYear':
//         startDate = sub(currentTime, { years: 1 });
//         return { startDate: startDate, endDate: currentTime, filterName: 'lastYear' };
//     }
//   };

//   const initDateName = 'last2Week';

//   const [dateRange, setDateRange] = React.useState(generateDateRange(initDateName, new Date()));

//   // bind to select date filter
//   const handleChangeForSelected = (lastName) => {
//     setDateRange(generateDateRange(lastName, new Date()));
//   };

//   //reserved custom config for lastDate
//   const lastDate = {
//     startDate: dateRange.startDate,
//     endDate: dateRange.endDate,
//   };

//   //reserved custom config for topDate
//   const topDate = {
//     startDate: dateRange.startDate,
//     endDate: dateRange.endDate,
//   };

//   //line chart option configuration
//   const lineOptions = {
//     responsive: true,
//     plugins: {
//       legend: {
//         display: false,
//       },
//       title: {
//         display: true,
//         text: 'Web Apps Usage Trend',
//         position: 'top',
//         align: 'start',
//         color: 'black',
//         font: {
//           size: 20,
//           color: 'black',
//         },
//         padding: {
//           top: 30,
//           left: 40,
//           bottom: 40,
//         },
//       },
//     },
//   };

//   //bar chart option configuration
//   const barOptions = {
//     indexAxis: 'y',
//     responsive: true,
//     plugins: {
//       legend: {
//         display: false,
//       },
//       title: {
//         display: true,
//         text: 'Top 10 Try-On Products/Shades',
//         position: 'top',
//         align: 'start',
//         color: 'black',
//         font: {
//           size: 20,
//           color: 'black',
//         },
//         padding: {
//           top: 30,
//           left: 40,
//           bottom: 40,
//         },
//       },
//     },
//   };

//   const lastData = {
//     labels: last.labels,
//     datasets: [
//       {
//         axis: 'y',
//         // label: `Request amount by ${formatDistance(new Date(dateRange.endDate), new Date(dateRange.startDate))} ago`,
//         data: last.data,
//         borderColor: 'rgb(255, 99, 132)',
//         backgroundColor: 'rgba(255, 99, 132, 0.5)',
//       },
//     ],
//   };

//   const topData = {
//     labels: top.labels,
//     datasets: [
//       {
//         // label: `Product top amount by ${formatDistance(new Date(dateRange.endDate), new Date(dateRange.startDate))} ago`,
//         data: top.data,
//         borderColor: 'rgb(30, 30, 132)',
//         backgroundColor: 'rgba(30, 30, 132, 0.5)',
//       },
//     ],
//   };

//   useEffect(() => {
//     document.title = 'AR-Makeup Console';
//     dispatch(action.getLast(lastDate.startDate, lastDate.endDate));
//     dispatch(action.getTop(topDate.startDate, topDate.endDate));
//     dispatch(action.getAudiencesAmount());
//   }, [dateRange.startDate, dateRange.endDate]);

//   return (
//     <DefaultLayout title="Home" className={classes.root}>
//       <Breadcrumb></Breadcrumb>
//       <Grid container spacing={2}>
//         <Grid item xs={1}>
//           <Button
//             className={classes.button}
//             color="primary"
//             startIcon={<Refresh />}
//             variant="contained"
//             onClick={() => {
//               window.location.reload(true);
//             }}
//           >
//             Refresh
//           </Button>
//         </Grid>
//         <Grid item xs={9}></Grid>
//         <Grid item xs={1}>
//           <FormControl className={classes.dateFilter}>
//             <InputLabel>Date Filter</InputLabel>
//             <NativeSelect
//               value={dateRange.filterName}
//               onChange={(event) => {
//                 handleChangeForSelected(event.target.value);
//               }}
//             >
//               {Object.keys(selectDateFilterMapping).map((name, index) => (
//                 <option
//                   key={index}
//                   value={name}
//                   disabled={['lastHour', 'lastDay', 'lastYear', 'lastThreeMonth'].includes(name)}
//                 >
//                   {selectDateFilterMapping[name]}
//                 </option>
//               ))}
//             </NativeSelect>
//           </FormControl>
//         </Grid>
//       </Grid>
//       <Grid container spacing={4}>
//         <Grid item xs={3}>
//           <Paper>
//             <DynamicRequestBoard
//               title={'Qty of Virtual Try-On'}
//               direction={'column'}
//               startDate={dateRange.startDate}
//               endDate={dateRange.endDate}
//             ></DynamicRequestBoard>
//           </Paper>
//         </Grid>
//         <Grid item xs={3}>
//           <Paper>
//             <Panel title={'Qty of Product/Shade'} amount={713} direction={'column'}></Panel>
//           </Paper>
//         </Grid>
//         <Grid item xs={3}>
//           <Paper>
//             <Panel title={'Qty of Web App'} amount={audience} direction={'column'}></Panel>
//           </Paper>
//         </Grid>
//       </Grid>
//       <Grid container spacing={4}>
//         <Grid item xs={6}>
//           <Paper style={{ padding: '0 45px 45px 45px' }}>
//             <Line options={lineOptions} data={lastData} />
//           </Paper>
//         </Grid>
//         <Grid item xs={6}>
//           <Paper style={{ padding: '0 45px 45px 45px' }}>
//             <Bar options={barOptions} data={topData} />
//           </Paper>
//         </Grid>
//       </Grid>
//     </DefaultLayout>
//   );
// });

// const mapStateToProps = ({ dashboard }) => ({
//   top: dashboard.top,
//   last: dashboard.last,
//   audience: dashboard.amount.byAudience,
// });

// export default connect(mapStateToProps)(DashboardPage);

import { withStyles } from '@material-ui/core/styles';

const DashboardPage = withStyles((theme) => ({
  root: {
    backgroundColor: '#F2F3F5',
    display: 'flex',
    flexDirection: 'column',
  },

  webIfrema: {
    margin: theme.spacing(2, 2, 2, 0),
    height: '100%',
    width: '100%',
  },
  redirectContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',
    alignContent: 'center',
    margin: 'auto',
  },

  redirectButton: {},
}))(({ classes }) => {
  // let embedConfiguration = {
  //   accessToken: anAccessToken,
  //   embedUrl: anEmbedUrl,
  //   id: aReportId,
  //   permissions: somePermissions,
  //   tokenType: aTokenType,
  //   type: 'report',
  // };

  // // Get a reference to the HTML element that contains the embedded report.
  // let embedContainer = $('#embedContainer')[0];

  // // Embed the report.
  // let report = powerbi.embed(embedContainer, embedConfiguration);

  // console.log(report);

  return (
    <DefaultLayout title="Dashboard" className={classes.root}>
      <Breadcrumb></Breadcrumb>
      <div className={classes.redirectContent}>
        <text style={{ fontSize: '12px' }}>Placse click button redirect to dashboard</text>
        <div style={{ paddingTop: '20px' }}>
          <Button
            onClick={() => {
              window.open(
                process.env.REACT_APP_EMBEDING_URL,
                'popUpWindow',
                'height=500px,width=800px,left=200px,top=100px,,scrollbars=yes,menubar=no',
              );
              return false;
            }}
            style={{ backgroundColor: 'black', fontFamily: { color: 'white' } }}
          >
            <span style={{ color: 'white' }}>Power BI Dashboard</span>
          </Button>
        </div>
      </div>
    </DefaultLayout>
  );
});

export default DashboardPage;
