import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, TextField } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles((theme) => ({
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: theme.spacing(0.5, 0.25),
    backgroundColor: 'lightgray',
    color: 'gray',
  },
  root: {
    padding: theme.spacing(0),
  },
  flexInput: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  minHeight: {
    height: 0,
    padding: '3px',
  },
  field: {
    margin: theme.spacing(0, 3),
  },
}));

export default (props) => {
  const classes = useStyles();
  const { data, fields, audiences, styles } = props;
  return (
    <Grid container className={classes.root} spacing={2}>
      {fields &&
        fields.map(({ label, multiline, name, span, component: Component }) => {
          return (
            <Grid item xs={span || 6} key={name}>
              <Box className={classes.field}>
                <TextField
                  fullWidth
                  id={name}
                  inputProps={{
                    readOnly: true,
                    className:
                      name === 'allowedAudiences' ||
                      name === 'allowedStyles' ||
                      name === 'createdDate' ||
                      name === 'lastModifiedDate'
                        ? classes.minHeight
                        : '',
                  }}
                  InputProps={{
                    className: classes.flexInput,
                    startAdornment: Component ? (
                      <Component id={data[name]} data={data} field={{ name: name }} />
                    ) : name === 'allowedAudiences' || name === 'allowedStyles' ? (
                      data[name] &&
                      data[name].map((item) => (
                        <Chip
                          key={item}
                          tabIndex={-1}
                          label={
                            name === 'allowedAudiences'
                              ? audiences.map((audience) => {
                                  return audience.value === item ? audience.text : '';
                                })
                              : styles.map((style) => {
                                  return style.value === item ? style.text : '';
                                })
                          }
                          className={classes.chip}
                        />
                      ))
                    ) : (
                      ''
                    ),
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  key={name}
                  label={label}
                  margin="normal"
                  multiline={multiline}
                  name={name}
                  // Component ? (
                  //   <Component id={row[name]} {...rest} data={row} field={{ ...rest, name: name }} />
                  // )
                  value={
                    name === 'allowedAudiences' ||
                    name === 'allowedStyles' ||
                    name === 'createdDate' ||
                    name === 'lastModifiedDate'
                      ? ''
                      : data[name]
                  }
                  disabled
                />
              </Box>
            </Grid>
          );
        })}
    </Grid>
  );
};
