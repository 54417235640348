import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import FormField from 'components/Form/FormField';
import React from 'react';
import { connect } from 'react-redux';
import * as action from 'redux/actions';
import { useFormik } from 'formik';
import { fieldsValidationSchema } from 'utils';
import * as yup from 'yup';

const Form = withStyles(() => ({
  root: {
    width: '100%',
  },
}))(({ classes, fields, formData, product, dispatch, onSubmit, formValidationSchema }) => {
  const handleChange = (name, value) => {
    if (name === 'shadesVersion') {
      Object.assign(formData, product.shadesVersion[value].shades[0]);
    }
    dispatch(action.setFormData({ ...formData, [name]: value }));
  };

  const handleDelete = (name, item) => {
    formData[name].splice(formData[name].indexOf(item), 1);
    dispatch(action.setFormData({ ...formData }));
  };

  const validationSchema = formValidationSchema || fieldsValidationSchema(fields);

  const formik = useFormik({
    initialValues: formData,
    validationSchema: yup.object(validationSchema),
    validateOnChange: true,
    onSubmit: (values) => {
      onSubmit(values);
    },
  });

  return (
    <form className={classes.root} noValidate id="formModalComponent" onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        {fields.map((field) => {
          return (
            <Grid item xs={field.span || 12} key={field.name}>
              <FormField
                component={field.component}
                data={formData}
                field={field}
                key={field.name}
                formik={formik}
                onChange={(value) => handleChange(field.name, value)}
                onDelete={(item) => handleDelete(field.name, item)}
                value={formik.values[field.name]}
                error={formik.touched[field.name] && Boolean(formik.errors[field.name])}
                helperText={formik.touched[field.name] && formik.errors[field.name]}
                onBlur={formik.handleBlur}
              />
            </Grid>
          );
        })}
      </Grid>
    </form>
  );
});

const mapStateToProps = ({ formData, product }) => ({
  formData,
  product,
});

export default connect(mapStateToProps)(Form);
