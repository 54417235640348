import * as api from 'api';
import * as action from 'redux/actions';
import _ from 'lodash';

export const createAudience = (data, pageParams) => (dispatch) => {
  dispatch(action.closeModal());
  dispatch(action.showLoading());
  api
    .createAudience(data)
    .then(() => {
      api.getAudiences(pageParams).then((response) => {
        dispatch(action.setAudiences(response.data));
      });
    })
    .then(() => {
      dispatch(action.setSuccess('The audience has been created.'));
    })
    .catch((error) => {
      dispatch(action.setError(error));
    })
    .finally(() => {
      dispatch(action.hideLoading());
    });
};

export const deleteAudience = (id, pageParams, resolve) => (dispatch) => {
  dispatch(action.closeModal());
  dispatch(action.showLoading());

  api
    .deleteAudience(id)
    .then(() => {
      if (resolve) {
        return resolve();
      }

      return api.getAudiences(pageParams).then((response) => {
        dispatch(action.setAudiencesPage(pageParams.page));
        dispatch(action.setAudiences(response.data));
      });
    })
    .then(() => {
      dispatch(action.setSuccess('The audience has been deleted.'));
    })
    .catch((error) => {
      dispatch(action.setError(error));
    })
    .finally(() => {
      dispatch(action.hideLoading());
    });
};

export const editAudience =
  (data, context = null, pageParams) =>
  (dispatch) => {
    dispatch(action.closeModal());
    dispatch(action.showLoading());

    api
      .editAudience(data)
      .then(() => {
        if (context === 'details') {
          return api.getAudience(data.id).then((response) => {
            const allowedOrigins = Array.isArray(response.data.allowedOrigins)
              ? _.join(response.data.allowedOrigins, '\n')
              : response.data.allowedOrigins;

            const responseData = { ...response.data, allowedOrigins };

            dispatch(action.setAudience(responseData));
          });
        } else {
          return api.getAudiences(pageParams).then((response) => {
            dispatch(action.setAudiences(response.data));
          });
        }
      })
      .then(() => {
        dispatch(action.setSuccess('The audience has been updated.'));
      })
      .catch((error) => {
        dispatch(action.setError(error));
      })
      .finally(() => {
        dispatch(action.hideLoading());
      });
  };

export const fetchAudience = (id) => (dispatch) => {
  dispatch(action.showLoading());
  api
    .getAudience(id)
    .then((response) => {
      const allowedOrigins = Array.isArray(response.data.allowedOrigins)
        ? _.join(response.data.allowedOrigins, '\n')
        : response.data.allowedOrigins;

      const responseData = { ...response.data, allowedOrigins };

      dispatch(action.setAudience(responseData));
      return Promise.resolve();
    })
    .catch((error) => {
      dispatch(action.setError(error));
    })
    .finally(() => {
      dispatch(action.hideLoading());
    });
};

export const fetchAudiences = (pageParams) => (dispatch) => {
  dispatch(action.showLoading());
  api
    .getAudiences(pageParams)
    .then((response) => {
      const responseResults = response.data.results.map((item) => {
        const allowedOrigins = _.join(item.allowedOrigins, '\n');

        return { ...item, allowedOrigins };
      });
      const responseData = { ...response.data, results: responseResults };
      const page = Math.ceil(response.data.offset / response.data.limit);
      dispatch(action.setAudiences(responseData));
      dispatch(action.setAudiencesPage(page));
      return Promise.resolve();
    })
    .catch((error) => {
      dispatch(action.setError(error));
    })
    .finally(() => {
      dispatch(action.hideLoading());
    });
};

export const fetchAudienceScope = (audience) => (dispatch) => {
  dispatch(action.showLoading());
  api
    .getAudiences(audience)
    .then((response) => {
      dispatch(action.setAudienceScope(response.data.results[0].scope));
      return Promise.resolve();
    })
    .catch((error) => {
      dispatch(action.setError(error));
    })
    .finally(() => {
      dispatch(action.hideLoading());
    });
};

export const openCreateAudienceModal = (data) => (dispatch) => {
  dispatch(action.setFormData(data));
  dispatch(action.openModal('createAudience'));
};

export const openEditAudienceModal = (id) => (dispatch) => {
  dispatch(action.showLoading());
  api
    .getAudience(id)
    .then((response) => {
      const allowedOrigins = Array.isArray(response.data.allowedOrigins)
        ? _.join(response.data.allowedOrigins, '\n')
        : response.data.allowedOrigins;

      const responseData = { ...response.data, allowedOrigins };

      dispatch(action.setFormData(responseData));
      dispatch(action.openModal('editAudience'));
      return Promise.resolve();
    })
    .catch((error) => {
      dispatch(action.setError(error));
    })
    .finally(() => {
      dispatch(action.hideLoading());
    });
};

export const openDeleteAudienceModal = (data) => (dispatch) => {
  dispatch(action.setFormData(data));
  dispatch(action.openModal('deleteAudience'));
};

export const filterAudience = (keyword) => (dispatch) => {
  dispatch(action.setAudienceFilter(keyword));
};

export const resetAudience = () => ({ type: 'SET_AUDIENCE', payload: {} });

export const setAudience = (audience) => ({
  type: 'SET_AUDIENCE',
  payload: audience,
});

export const setAudiences = (audiences) => ({
  type: 'SET_AUDIENCES',
  payload: audiences,
});

export const setAudienceFilter = (keyword) => ({
  type: 'SET_AUDIENCES_FILTER',
  payload: keyword,
});

export const setAudiencesPage = (page) => ({
  type: 'SET_AUDIENCES_PAGE',
  payload: page,
});

export const setAudienceScope = () => ({
  type: 'SET_AUDIENCE_SCOPE',
  payload: 'hair/0.9.7',
});
