import React from 'react';
import SingleSlider from './SingleSlider';

export default ({ data, field, onChange }) => {
  const handleChange = (index, newValue) => {
    const retVal = data[field.name].slice(0);
    retVal[index] = newValue;
    onChange(retVal);
  };

  return (
    <>
      <SingleSlider
        disabled={field.disabled}
        label="Red"
        maxValue={255}
        minValue={0}
        onChange={(newValue) => handleChange(0, newValue)}
        step={1}
        value={data[field.name][0]}
      />
      <SingleSlider
        disabled={field.disabled}
        label="Green"
        maxValue={255}
        minValue={0}
        onChange={(newValue) => handleChange(1, newValue)}
        step={1}
        value={data[field.name][1]}
      />
      <SingleSlider
        disabled={field.disabled}
        label="Blue"
        maxValue={255}
        minValue={0}
        onChange={(newValue) => handleChange(2, newValue)}
        step={1}
        value={data[field.name][2]}
      />
    </>
  );
};
