import ArMakeup from 'ar-makeup-module';

export const init = () => {
  return ArMakeup.init({
    baseUrl: '',
    containerId: 'armakeup',
    relativeSDKPath: `sdk/hair/0.9.7`,
    relativeExternalPath: 'external',
    relativeStylesPath: 'styles/resources',
  })
    .then(() => {
      return ArMakeup.set({ split: false });
    })
    .catch((error) => {
      console.error(error);
      return Promise.reject(error);
    });
};

export const close = () => {
  return ArMakeup.set({ mode: 'closed' }).catch((error) => {
    console.error(error);
    return Promise.reject(error);
  });
};

export const get = () => {
  return ArMakeup.get().catch((error) => {
    console.error(error);
    return Promise.reject(error);
  });
};

export const makeup = (selected) => {
  return ArMakeup.set(selected).catch((error) => {
    console.error(error);
    return Promise.reject(error);
  });
};

export const setSplitScreen = () => {
  return ArMakeup.get()
    .then(({ split }) => {
      return ArMakeup.set({ split: !split });
    })
    .catch((error) => {
      console.error(error);
      return Promise.reject(error);
    });
};

export const setPhotoMode = (photoImg) => {
  return ArMakeup.set({
    mode: 'photo',
    photoImg,
  }).catch((error) => {
    console.error(error);
    return Promise.reject(error);
  });
};

export const setCameraMode = () => {
  return ArMakeup.set({
    mode: 'camera',
    photoImg: null,
  }).catch((error) => {
    console.error(error);
    return Promise.reject(error);
  });
};

export const destroy = () => {
  return ArMakeup.destroy()
    .then(() => {
      const destroyScript = ['#armakeup-styles', '#gl-matrix-min', '#st-ar-makeup'];
      destroyScript.forEach((item) => {
        removeElementByQuerySelector(item);
      });
    })
    .catch((error) => {
      console.error(error);
      return Promise.reject(error);
    });
};

const removeElementByQuerySelector = (selector) => {
  const element = document.querySelector(selector);
  if (element) {
    element.remove();
  }
};
