import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select as MuiSelect } from '@material-ui/core';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles((theme) => ({
  audiences_placeholder: {
    marginLeft: theme.spacing(0.5),
    opacity: 0.5,
  },
  hide: {
    display: 'none',
  },
}));

const Select = ({
  data,
  field: { label, name, required, variant, minWidth, multiple, frmCreate, frmEdit },
  helperText,
  onChange,
  options,
  formik,
  error,
}) => {
  const classes = useStyles();
  const handleChange = (e) => {
    formik.handleChange(e);
    onChange(e.target.value);
  };

  return (
    <FormControl
      disabled={options.length === 0}
      style={{ width: `${minWidth}px` }}
      fullWidth={minWidth ? false : true}
      margin="normal"
      required={required}
      variant={variant || 'standard'}
      error={error}
    >
      <InputLabel htmlFor={`select-${name}`} required={required}>
        {label}
      </InputLabel>
      <MuiSelect
        inputProps={{ id: `select-${name}` }}
        label={`${label}${required && ' *'}`}
        // helperText={error ? helperText : helperText}
        displayEmpty={multiple ? true : false}
        name={name}
        multiple={multiple}
        onChange={handleChange}
        value={data[name]}
        renderValue={
          multiple
            ? (frmCreate || frmEdit
              ? ((selected) => (
                <div className={classes.chips}>
                  {selected.map((value) =>
                    value.includes('#') ? (
                      <Chip key={value.split('#')[0]} label={value.split('#')[1]} className={classes.chip} />
                    ) : (
                      <Chip
                        key={value}
                        label={options.map((option) => {
                          return option.value === value ? option.text : '';
                        })}
                        className={classes.chip}
                      />
                    ),
                  )}
                </div>
              ))
              : ((selected) => {
                return selected.length > 1 ? (
                  selected[0].split('#')[1] + `(+${selected.length - 1})`
                ) : selected.length === 0 ? (
                  name === 'audiences' ? (
                    <label className={classes.audiences_placeholder}>Audiences</label>
                  ) : (
                    ''
                  )
                ) : (
                  selected[0].split('#')[1]
                );
              }))
            : ''
        }
      >
        <MenuItem className={name !== 'allowedAudiences' ? classes.hide : ''} disabled value="">
          <label className={classes.audiences_placeholder}>Audiences</label>
        </MenuItem>
        {options.map((option) => {
          if (multiple) {
            return (
              <MenuItem
                value={multiple ? (frmEdit ? option.value : option.value + '#' + option.text) : option.value}
                key={option.value}
              >
                <Checkbox
                  style={{ color: '#000000', backgroundColor: 'transparent' }}
                  checked={
                    data[name].indexOf(option.value + '#' + option.text) > -1 || data[name].indexOf(option.value) > -1
                  }
                />
                <ListItemText primary={option.text} />
              </MenuItem>
            );
          } else {
            return (
              <MenuItem value={option.value} key={option.text}>
                <ListItemText primary={option.text} />
              </MenuItem>
            );
          }
        })}
      </MuiSelect>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};

const mapStateToProps = (state, { field: { lookup, options = [] } }) => ({
  options: lookup ? state[lookup] : options,
});

export default connect(mapStateToProps)(Select);
