import {
  Button,
  Dialog,
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  DialogContentText,
  DialogTitle as MuiDialogTitle,
  IconButton,
  Typography,
  Grid,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Close } from '@material-ui/icons';
import Form from 'components/Form/Form';
import React from 'react';
import { connect } from 'react-redux';
import * as action from 'redux/actions';
import ArMakeup from 'components/ArMakeup/ArMakeup';
import { destroy } from 'utils/ArMakeup';

const DialogTitle = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}))((props) => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose && (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <Close />
        </IconButton>
      )}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
}))(MuiDialogActions);

const FormModal = withStyles(() => ({
  root: {
    minWidth: 640,
  },
  arModal: {
    width: '1000px',
  },
  formModal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))(
  ({
    classes,
    component: Component = Form,
    contentText,
    dispatch,
    fields,
    formData,
    onSubmit,
    open,
    size = 'lg',
    title,
    part,
    validationSchema,
  }) => {
    const closeModal = () => {
      destroy();
      dispatch(action.closeModal());
    };

    return (
      <Dialog className={classes.root} maxWidth={size} open={open} onClose={closeModal}>
        <DialogTitle id="form-dialog-title" onClose={closeModal}>
          {title}
        </DialogTitle>
        {(title === 'Create Shade' || title === 'Release Shade') && part === 'haircolor' ? (
          <DialogContent className={classes.arModal}>
            <Grid container>
              <Grid item xs={7} className={classes.formModal}>
                <ArMakeup></ArMakeup>
              </Grid>
              <Grid item xs={5} className={classes.formModal}>
                <DialogContentText>{contentText}</DialogContentText>
                <Component
                  fields={fields}
                  formValidationSchema={validationSchema}
                  formData={formData}
                  onSubmit={onSubmit}
                />
              </Grid>
            </Grid>
          </DialogContent>
        ) : (
          <DialogContent>
            <DialogContentText>{contentText}</DialogContentText>
            <Component
              fields={fields}
              formValidationSchema={validationSchema}
              formData={formData}
              onSubmit={onSubmit}
            />
          </DialogContent>
        )}
        <DialogActions>
          <Button variant="contained" form="formModalComponent" color="primary" type="submit">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    );
  },
);

const mapStateToProps = ({ formData, openingModal }, { name }) => ({
  formData,
  open: openingModal === name,
});

export default connect(mapStateToProps)(FormModal);
